<template>
  <v-container v-if="!allcategory">
    <v-row class="loaderHeight">
      <v-col cols="2" align-self="center" offset="5">
        <v-progress-linear
          max-width="150"
          color="black"
          height="6"
          indeterminate
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="mt-15 d-flex align-center ">
    <v-row dense justify="center">
      <v-col
        v-for="(product, index) in allcategory"
        :key="index"
        style="max-width:340px;"
      >
        <v-card
          class="mx-1 d-flex align-center justify-center"
          max-width="330"
          :to="{
            name: 'Products',
            params: { id: product.name }
          }"
          :loading="loading"
          elevation="0"
          ripple
          :img="product.imgSrc"
          height="500"
        >
          <v-card-title>
            <v-btn block tile depressed class="py-9" x-large>
              {{ product.name }}
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "categories",
  data() {
    return {
      allcategory: [
        {
          name: "Handtufted Rugs",
          imgSrc: "/img/ramsha_handtufted.jpg",
          assignBox: "Ramsha480"
        },
        {
          name: "Novara Rugs",
          imgSrc: "/img/ramsha_novara.jpg",
          assignBox: "Novara"
        },
        {
          name: "Majlis Carpets",
          imgSrc: "/img/Majlis.png",
          assignBox: "Ramsha480"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
