<template>
  <v-container>
    <v-snackbar
      :timeout="topNotification.timeOut"
      :value="topNotification.visible"
      top
      min-width="350"
      class="my-6 ml-14"
      transition="slide-y-transition"
    >
      <v-icon left :color="topNotification.color">mdi-check-circle</v-icon>
      {{ topNotification.message }}
    </v-snackbar>
    <v-row class="mt-3">
      <v-col>
        <h1 class="display-1 font-weight-bold">Add New Product</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-row>
        <v-col cols="4" class="pa-5 mt-5 grey lighten-5">
          <form>
            <v-text-field
              v-model="design"
              label="Design Code"
              required
              outlined
              dense
              clearable
            ></v-text-field>
            <v-select
              v-model="selectproductType"
              :items="productType"
              label="Product Type"
              required
              outlined
              dense
              clearable
            ></v-select>
            <v-select
              v-model="boxname"
              :items="boxnames"
              label="Select Color Reference Box"
              required
              outlined
              dense
              clearable
              @change="boxSelection"
            ></v-select>

            <v-select
              v-model="pattern"
              :items="patterntype"
              label="Pattern Type"
              required
              outlined
              dense
              clearable
            ></v-select>
            <v-text-field
              v-model="material"
              label="Material"
              required
              clearable
              outlined
              dense
            ></v-text-field>

            <v-textarea
              v-model="description"
              label="Product Description"
              required
              clearable
              outlined
              dense
              no-resize
            ></v-textarea>
            <v-switch
              v-model="visible"
              :label="`${visible ? 'Active' : 'Disabled'}`"
            ></v-switch>
            <v-btn
              class="mr-1 black white--text"
              small
              @click="submit"
              depressed
            >
              Add Product
            </v-btn>
            <v-btn @click="randomColor()" depressed color="info" small>
              Random Colors
            </v-btn>
            <v-btn
              @click="clear"
              class="ml-1"
              depressed
              small
              color="red white--text"
            >
              clear
            </v-btn>
          </form>
          <!-- show thumbnail preview -->
          <v-row>
            <h3 class="mt-9 mx-auto">Thumbnail Preview</h3>
          </v-row>
          <v-row>
            <v-img
              contain
              :src="thumb"
              width="313"
              height="500"
              class="mt-5"
            ></v-img>
          </v-row>
        </v-col>
        <v-col cols="5" class="mt-5 ml-10">
          <v-row class="mb-2">
            <div id="compContainer" class="ml-0">
              <div id="baseimge">
                <img
                  v-if="productLayers[0].path !== ''"
                  src="img/Lndscapes/JPEG/170X240/LD-01.jpg"
                  alt="base"
                />
                <img v-else src="img/NoImageData.gif" alt="base" />
              </div>

              <svg
                viewbox="5 0 800 500"
                style="position: absolute; z-index: 5;"
              ></svg>

              <div v-for="product in productLayers" :key="product.id">
                <svg
                  :id="product.id"
                  class="base"
                  :style="{ fill: product.color }"
                  width="796"
                  height="496"
                  viewBox="0 -4 800 500"
                  v-html="product.path"
                ></svg>
              </div>
            </div>
          </v-row>

          <v-row
            no-gutters
            v-for="(input, id) in productLayers"
            :key="id"
            class="mt-3 mb-n9"
          >
            <v-col cols="8"
              ><v-text-field
                v-model="input.path"
                prepend-inner-icon="mdi-close-circle"
                clearable
                label="SVG Data"
                required
                outlined
                @input="$v.email.$touch()"
                @click:prepend-inner="clearLayerRow(id)"
                @change="genrateThumbs()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" class="ml-1 mr-n2">
              <v-autocomplete
                v-model="input.color"
                :items="boxColors"
                outlined
                clearable
                label="Select Color"
                placeholder="No Color Selected"
                item-text="ShadeNumber"
                item-value="HEX"
                @change="setCurrentShadeNumber(id)"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :color="data.item.HEX"
                    text-color="black"
                    outlined
                  >
                    <v-avatar
                      left
                      :color="data.item.HEX"
                      style="border: 1px solid black;"
                    >
                    </v-avatar>
                    {{ data.item.ShadeNumber }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar
                      size="32"
                      :color="data.item.HEX"
                      style="border: 1px solid black;"
                    >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.ShadeNumber"
                      ></v-list-item-title>
                      <v-list-item-subtitle class="caption">
                        {{ boxname }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-btn @click="addLayer()" depressed color="info" class="my-5">
            <v-icon left dark>
              mdi-plus
            </v-icon>
            Add Layer
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-for="item in allDesigns" :key="item.deasignName" class="mt-5">
      <v-btn @click="fetchProduct(item.deasignName)" text depressed>{{
        item.deasignName
      }}</v-btn>
      <span>{{ item.visible }}</span>
    </v-row>
  </v-container>
</template>

<script>
import colorBoxesData from "@/assets/box.json";
import { db } from "@/utils/firebase";
import { toPng } from "html-to-image";
export default {
  data: () => ({
    boxSel: colorBoxesData.Novara,
    visible: true,
    thumb: "",
    color: "",
    productLayers: [
      {
        id: 0,
        ShadeNumber: "",
        color: "",
        path: ""
      }
    ],
    counter: 1,
    date: Date.now(),
    design: "",
    material: "",
    description: "",
    pattern: null,
    selectproductphType: null,
    allDesigns: [],
    boxColors: [],
    boxname: "", //// set the default colors on mount from json
    topNotification: {
      color: "success",
      icon: "mdi-check-decagram",
      message: "Product Added Successfully!",
      timeOut: 4000,
      visible: false
    },
    patterntype: [
      "Modern",
      "Abstract",
      "Geometrical",
      "Damask",
      "Lines",
      "Persian",
      "Floral"
    ],
    productType: [
      "Novara Rugs",
      "Handknotted Rugs",
      "Handtufted Rugs",
      "Majlis Carpets",
      "Flat Weave"
    ],
    boxnames: ["Ramsha Novara Shades", "Ramsha 480 Shades"]
  }),
  mounted() {
    this.getProducts();
  },

  methods: {
    addLayer() {
      this.productLayers.push({
        id: this.counter,
        ShadeNumber: "",
        color: "",
        path: ""
      });
      this.counter++;
    },
    clearLayerRow(index) {
      this.productLayers.splice(index, 1);
      this.color = "No Color Selected";
    },
    updateProduct(id) {
      db.collection("products")
        .doc(id)
        .update({
          visible: this.visible
        });
    },
    submit() {
      db.collection("products")
        .doc(this.design)
        .set({
          deasignName: this.design,
          productType: this.selectproductType,
          patternType: this.pattern,
          shadebox: this.boxname,
          material: this.material,
          description: this.description,
          productLayers: this.productLayers,
          visible: this.visible,
          createdAt: new Date(),
          thumb: `https://customize.ramshacarpets.com/thumbs/${this.design}${this.date}.jpg`
        })
        .then(() => {
          this.saveThumbs();
          this.topNotification.visible = true;
          // console.log("Document successfully written!");
          this.clear();
        })
        .catch(error => {
          this.topNotification.visible = true;
          this.topNotification.color = "red";
          this.topNotification.message = error;
          this.topNotification.icon = "mdi-alert-circle-outline";
          console.error("Error writing document: ", error);
        });
    },

    getProducts() {
      db.collection("products")
        .get()
        .then(querySnapshot => {
          console.log(process.env.VUE_APP_FIREBASE_CONFIG);
          let docs = querySnapshot.docs.map(doc => doc.data());
          for (let doc of docs) {
            this.allDesigns.push(doc);
          }
        });
    },
    fetchProduct(id) {
      this.clear();
      db.collection("products")
        .doc(id)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.productLayers = doc.data().productLayers;
            this.design = doc.data().deasignName;
            this.material = doc.data().material;
            this.boxname = doc.data().shadebox;
            this.selectproductType = doc.data().productType;
            this.pattern = doc.data().patternType;
            this.description = doc.data().description;
            this.visible = doc.data().visible;
            this.thumb = doc.data().thumb;
            this.boxSelection();
          } else {
            // doc.data() will be undefined in this case
            this.topNotification.visible = true;
            this.topNotification.color = "red";
            this.topNotification.message = "No such document!";
            console.log(doc.data().thumb);
          }
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });
    },
    clear() {
      this.design = "";
      this.material = null;
      this.boxname = null;
      this.selectproductType = null;
      this.pattern = null;
      this.description = null;
      this.color = "";
      this.counter = 1;
      this.thumb = "";
      this.productLayers = [
        {
          id: 0,
          ShadeNumber: "",
          color: "",
          path: ""
        }
      ];
    },
    ///Set the shadenumbers to current product array on dropdown on selection
    setCurrentShadeNumber(id) {
      for (var i = 0; i < this.boxColors.length; i++) {
        if (this.boxColors[i].HEX == this.productLayers[id].color) {
          this.productLayers[id].ShadeNumber = this.boxColors[i].ShadeNumber;
        }
      }
      this.genrateThumbs();
    },

    //genreate thumbnail png data
    genrateThumbs() {
      let node = document.getElementById("compContainer");

      toPng(node, {
        quality: 0.6,
        width: 500,
        height: 800,
        style: {
          transform: `rotate(90deg) scale(.8)`,
          width: "200px",
          background: "#fff"
        }
      }).then(dataUrl => {
        this.thumb = dataUrl;
      });
    },
    //make the box selection from dropdown
    boxSelection() {
      if (this.boxname == this.boxnames[1]) {
        this.boxSel = colorBoxesData.Ramsha480;
      } else {
        this.boxSel = colorBoxesData.Novara;
      }
      this.boxColors = [];
      for (var x in this.boxSel) {
        var obj = this.boxSel[x];
        this.boxColors.push(obj);
      }
    },
    //save thumbnail to server in PNG image format
    saveThumbs() {
      let data = new FormData();
      data.append("data", this.thumb);
      data.append("name", this.design + this.date);
      let xhr = new XMLHttpRequest();

      xhr.open(
        "POST",
        "https://customize.ramshacarpets.com/thumbSave.php",
        true
      );

      xhr.send(data);
    },
    //generate random color pallet from current box selection
    randomColor() {
      for (const key in this.productLayers) {
        let ran = Math.floor(Math.random() * this.boxColors.length);
        let lyrObj = this.productLayers[key];
        if (lyrObj["path"] != "") {
          lyrObj["color"] = this.boxColors[ran].HEX;
        }
      }
      this.genrateThumbs();
    }
  }
};
</script>
<style lang="scss" scoped>
#compContainer {
  z-index: 0;
  position: relative;
  width: 500px;
  height: 500px;
  align-items: center;
  background: rgb(255, 255, 255);
}

#baseimge {
  z-index: 1;
  position: absolute;
  background: rgb(255, 255, 255);
  //  filter: drop-shadow(3px 3px 2px rgb(32, 32, 32));
}
</style>
